<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable :value="products" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<div>
								<InputSwitch v-model="$store.state.opc"  style="margin-top: 0" class="mr-2"/>
								<InputText id="fecha"  v-model="$store.state.ini" type="date" v-if="!$store.state.opc" class="mr-2"/>
								<InputText id="fecha"  v-model="$store.state.fin" type="date" v-if="!$store.state.opc" class="mr-2"/>
								<Dropdown id="state" v-model="$store.state.year" :options="$store.state.years" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." @change="$store.state.mes=nul" v-if="$store.state.opc"></Dropdown>
								<Dropdown id="state" v-model="$store.state.mes" :options="$store.state.meses" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." v-if="$store.state.opc"></Dropdown>
								<Dropdown v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Selecciona..." class="mr-2"></Dropdown>
								<Button icon="pi pi-search" class="p-button-rounded p-button-info mr-2" @click="Buscar"/>
							</div>
							Total: {{products.length}}
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="Nuevo" v-if="insert"/>
						</div>
					</template>
					<Column field="fechad" header="Fecha" headerStyle="width: 10%">
						<template #body="slotProps">
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="control" header="Nº Control">
						<template #body="slotProps">
							{{slotProps.data.control}}
						</template>
					</Column>
					<Column field="tipopago_nombre" header="Tipo de Pago">
						<template #body="slotProps">
							{{slotProps.data.tipopago_nombre}}
						</template>
					</Column>
					<Column field="referencia" header="Referencia" headerStyle="width: 10%">
						<template #body="slotProps">
							{{slotProps.data.referencia}}
						</template>
					</Column>
					<Column field="monto" header="Tasa Bs" headerStyle="width: 10%">
						<template #body="slotProps">
							{{Moneda(slotProps.data.tasa)}}
						</template>
					</Column>
					<Column field="monto" header="Monto $" headerStyle="width: 10%">
						<template #body="slotProps">
							<strong v-if="slotProps.data.monto>0 && slotProps.data.moneda==2" style="color: blue;">{{Moneda(slotProps.data.monto)}}</strong>
                            <strong v-if="slotProps.data.monto<0 && slotProps.data.moneda==2" style="color: red;">{{Moneda(slotProps.data.monto)}}</strong>
						</template>
					</Column>
					<Column field="monto" header="Balance $" headerStyle="width: 10%">
						<template #body="slotProps">
							<strong v-if="slotProps.data.balance>0" style="color: black;">{{Moneda(slotProps.data.balance)}}</strong>
                            <strong v-if="slotProps.data.balance<0" style="color: red;">{{Moneda(slotProps.data.balance)}}</strong>
						</template>
					</Column>

					<Column field="montobs" header="Monto Bs" headerStyle="width: 10%">
						<template #body="slotProps">
							<strong v-if="slotProps.data.montobs>0 && slotProps.data.moneda==1" style="color: blue;">{{Moneda(slotProps.data.montobs)}}</strong>
                            <strong v-if="slotProps.data.montobs<0 && slotProps.data.moneda==1" style="color: red;">{{Moneda(slotProps.data.montobs)}}</strong>
						</template>
					</Column>
					<Column field="montobs" header="Balance Bs" headerStyle="width: 10%">
						<template #body="slotProps">
							<strong v-if="slotProps.data.balancebs>0" style="color: black;">{{Moneda(slotProps.data.balancebs)}}</strong>
                            <strong v-if="slotProps.data.balancebs<0" style="color: red;">{{Moneda(slotProps.data.balancebs)}}</strong>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Administracion','Balance');
    import Funciones from "../service/Class";
    const Aplic = new Funciones();
	export default {
		components: {
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
                DialogProcesar: false,
				productDialog: false,
				deleteDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
                dataprincipal: [],
				opcion: null,
				opciones: [
					{nombre: 'Todas', code: 0},
					{nombre: 'Sólo Bs', code: 1},
					{nombre: 'Efectivo $', code: 2},
					{nombre: 'Zelle', code: 3}
				],
                proveedores: [],
                tipopago: [],
                caja: 0,
                banco: 0,
                cajaf: 0,
                bancof: 0,
                formapagos: [],
                totales: {
                    subtotal: 0,
                    descuento: 0,
                    iva: 0,
					final: 0,
                },

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
            Buscar(){
				this.selectedCustomers = [];
				let opciones = {
					filt: this.opcion.code,
					opc: this.$store.state.opc,
					ini: this.$store.state.ini,
					fin: this.$store.state.fin,
					mes: this.$store.state.mes.id,
					year: this.$store.state.year.id,
				};
				Consulta.Procesar('Buscar',{
                    product: opciones,
				}).then(response => {
					//this.$store.state.error = response.result[0];
					this.$store.commit('Loading');
					if (response.result) {
						this.products = response.result;
					}
				});
            },
            Moneda(value) {
                return Aplic.Moneda(value);
             },
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
